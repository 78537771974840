<template>
  <div class="engine-details">
    <div class="mb-4">
      <h4 class="mb-4 text-center">{{ $t('indicators.global') }}</h4>
    </div>
    <div class="dash-score mb-2">
      <AppDashScore :score="result.score" :stars="stars" />
    </div>
      <p class="global-score-description">{{ $t('indicators.global_score')}}</p>
    <Indicators :indicators="result.indicators" />
  </div>
  <InfoModal />
</template>

<script>
import Indicators from '@/components/engines/Indicators';
import InfoModal from '@/components/engines/InfoModal';

import envScores from '@/utils/data/envScores';
import { enginesEn, enginesFr } from '@/utils/data/engines';

export default {
  name: 'EngineDetails',
  props: ['id'],
  components: {
    InfoModal,
    Indicators,
  },
  data() {
    return {
      scores: {},
      engine: {},
      result: {},
      stars: null,
    };
  },
  created() {
    this.lang = this.$i18n.locale;
    this.scores = envScores.find((score) => score.id === this.id);

    if (this.lang === 'en') {
      this.engine = enginesEn.find((engine) => engine.id === this.id);
    }

    if (this.lang === 'fr') {
      this.engine = enginesFr.find((engine) => engine.id === this.id);
    }

    this.result = {
      ...this.scores,
      ...this.engine,
    };

    this.stars = this.result.stars;

    console.log(this.result);
  },
};
</script>

<style lang="scss">
.engine-details {
  padding: 0.5rem;
}
</style>