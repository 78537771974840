/**
 * Impact for 100km
 * Scores are relative: As the max score is 8.5 all the scores are relative to 85 = 100.
 * @param {number} score - Final score with single score
 * @param {number} climate - Climate change
 * @param {number} respiratory - Respiratory inorganics life cycle
 * @param {number} carriers - Resource use, energy carriers
 * @param {number} materials - Resource use, mineral et metals
 * @param {number} single - Single score score
 */

const envScores = [
  {
    id: 'bev-small',
    name: 'bev',
    score: 100,
    stars: 5,
    battery: 'small',
    chart: 'BEV (small)',
    indicators: [
      { name: 'climate', score: 100, impact: '8.97' },
      { name: 'respiratory', score: 100, impact: '6.84 e-7' },
      { name: 'carriers', score: 100, impact: '144.36' },
      { name: 'materials', score: 50, impact: '1.05 e-3' },
      { name: 'single', score: 70, impact: '2.26' },
    ],
  },
  {
    id: 'cng',
    name: 'cng',
    score: 89,
    stars: 4,
    chart: 'CNG',
    indicators: [
      { name: 'climate', score: 60, impact: '17.48' },
      { name: 'respiratory', score: 70, impact: '8.38 e-7' },
      { name: 'carriers', score: 50, impact: '258.00' },
      { name: 'materials', score: 100, impact: '3.15 e-4' },
      { name: 'single', score: 100, impact: '1.67' },
    ],
  },
  {
    id: 'bev-medium',
    name: 'bev',
    score: 79,
    stars: 4,
    battery: 'medium',
    chart: 'BEV (medium)',
    indicators: [
      { name: 'climate', score: 90, impact: '10.43' },
      { name: 'respiratory', score: 80, impact: '7.61 e-7' },
      { name: 'carriers', score: 90, impact: '169.10' },
      { name: 'materials', score: 20, impact: '1.34 e-3' },
      { name: 'single', score: 30, impact: '2.78' },
    ],
  },
  {
    id: 'fcev',
    name: 'fcev',
    score: 68,
    stars: 3,
    chart: 'FCEV',
    indicators: [
      { name: 'climate', score: 80, impact: '13.21' },
      { name: 'respiratory', score: 50, impact: '9.48 e-7' },
      { name: 'carriers', score: 90, impact: '159.31' },
      { name: 'materials', score: 40, impact: '1.08 e-3' },
      { name: 'single', score: 40, impact: '3.38' },
    ],
  },
  {
    id: 'bev-large',
    name: 'bev',
    score: 56,
    stars: 3,
    battery: 'large',
    chart: 'BEV (large)',
    indicators: [
      { name: 'climate', score: 80, impact: '12.05' },
      { name: 'respiratory', score: 70, impact: '8.47 e-7' },
      { name: 'carriers', score: 80, impact: '197.57' },
      { name: 'materials', score: 0, impact: '1.68 e-3' },
      { name: 'single', score: 0, impact: '3.38' },
    ],
  },
  {
    id: 'phev-high',
    name: 'phev',
    score: 53,
    stars: 3,
    energy: 'high',
    chart: 'PHEV (high)',
    indicators: [
      { name: 'climate', score: 60, impact: '16.55' },
      { name: 'respiratory', score: 30, impact: '1.05^-6' },
      { name: 'carriers', score: 50, impact: '257.23' },
      { name: 'materials', score: 50, impact: '9.96 e-4' },
      { name: 'single', score: 40, impact: '2.74' },
    ],
  },
  {
    id: 'petrol',
    name: 'petrol',
    score: 40,
    stars: 2,
    chart: 'Petrol',
    indicators: [
      { name: 'climate', score: 0, impact: '28.74' },
      { name: 'respiratory', score: 30, impact: '1.06 e-4' },
      { name: 'carriers', score: 0, impact: '387.91' },
      { name: 'materials', score: 100, impact: '3.27 e-4' },
      { name: 'single', score: 60, impact: '2.36' },
    ],
  },
  {
    id: 'diesel',
    name: 'diesel',
    score: 40,
    stars: 2,
    chart: 'Diesel',
    indicators: [
      { name: 'climate', score: 10, impact: '27.62' },
      { name: 'respiratory', score: 20, impact: '1.08 e-6' },
      { name: 'carriers', score: 0, impact: '394.87' },
      { name: 'materials', score: 100, impact: '3.3 e-4' },
      { name: 'single', score: 60, impact: '2.38' },
    ],
  },
  {
    id: 'hev',
    name: 'hev',
    score: 38,
    stars: 2,
    chart: 'HEV',
    indicators: [
      { name: 'climate', score: 20, impact: '25.19' },
      { name: 'respiratory', score: 20, impact: '1.08 e-6 '},
      { name: 'carriers', score: 20, impact: '338.77' },
      { name: 'materials', score: 70, impact: '6.97 e-4' },
      { name: 'single', score: 40, impact: '2.69' },
    ],
  },
  {
    id: 'phev-medium',
    name: 'phev',
    score: 32,
    stars: 2,
    energy: 'medium',
    chart: 'PHEV (medium)',
    indicators: [
      { name: 'climate', score: 30, impact: '22.11' },
      { name: 'respiratory', score: 10, impact: '1.12 e-6 '},
      { name: 'carriers', score: 30, impact: '317.56' },
      { name: 'materials', score: 50, impact: '9.92 e-4' },
      { name: 'single', score: 20, impact: '3.29' },
    ],
  },
  {
    id: 'phev-low',
    name: 'phev',
    score: 12,
    stars: 1,
    energy: 'low',
    chart: 'PHEV (low)',
    indicators: [
      { name: 'climate', score: 10, impact: '27.75' },
      { name: 'respiratory', score: 0, impact: '1.12 e-6' },
      { name: 'carriers', score: 10, impact: '378.9' },
      { name: 'materials', score: 50, impact: '9.87 e-4' },
      { name: 'single', score: 0, impact: '3.29' },
    ],
  },
];

export default envScores;
