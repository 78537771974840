<template>
  <div class="container-fluid banner">
    <div class="row text-center">
      <div class="banner-text">
        <h2>{{ $t('explore.title') }}</h2>
        <p>
          {{ $t('explore.description') }}
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <ExploreEngine />
  </div>
</template>

<script>
import ExploreEngine from '@/components/explore/ExploreEngine';

export default {
  name: 'Explore',
  components: {
    ExploreEngine,
  },
};
</script>

<style lang="scss">
.explore-row {
  padding-bottom: 3rem;
}
</style>
