<template>
  <div class="col-12 col-lg-6 legend-col">
    <table class="table">
      <thead>
        <tr>
          <td>
            <h4>{{ $t('indicators.legend') }}</h4>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Climate</td>
          <td>{{ $t('indicators.climate.title') }}</td>
        </tr>
        <tr>
          <td>Particulate</td>
          <td>{{ $t('indicators.respiratory.title') }}</td>
        </tr>
        <tr>
          <td>Fossils</td>
          <td>{{ $t('indicators.carriers.title') }}</td>
        </tr>
        <tr>
          <td>Materials/Minerals</td>
          <td>{{ $t('indicators.materials.title') }}</td>
        </tr>
        <tr>
          <td>Single score</td>
          <td>
            {{ $t('indicators.single.title') }}
            <a
              href="https://eplca.jrc.ec.europa.eu/uploads/Science_for_policy_report_final_on_line.pdf"
              target="_blank"
              ><small style="margin-left: 0.5rem"
                >({{ $t('nav.details') }})</small
              ></a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ChartLegend',
};
</script>

<style lang="scss" scoped>
.legend-col {
  margin: 0 auto;
  max-width: fit-content;
  h4 {
    font-size: 16px;
  }
  tbody {
    font-size: 14px;
  }
}
</style>
