<template>
  <div class="row explore-row content-row">
    <div class="col-12 col-lg-6 engines-list mt-4">
      <el-select
        v-model="selected"
        :placeholder="$t('nav.select')"
        @change="updateChart"
      >
        <el-option
          v-for="choice in choices"
          :key="choice.id"
          :label="$t(`select.${choice.id}`)"
          :value="choice.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-12 col-lg-6 mt-4 btn-col">
      <app-button mode="small-fz" @clicked="showModal = true">{{
        $t('explore.profile')
      }}</app-button>
    </div>
    <div class="col-12 description-col">
      <p>
        <i class="bi bi-info-square-fill"></i> {{ $t('indicators.percentage') }}
      </p>
    </div>
  </div>
  <div class="row chart-row content-row">
    <div class="col-12 col-lg-6 chart-col">
      <apexchart
        height="500"
        :width="chartWidth"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
    <ChartLegend />
  </div>
  <el-dialog v-model="showModal" destroy-on-close>
    <template #title>
      <h4 class="text-primary">{{ options.title.text }}</h4>
    </template>
    <el-scrollbar height="600px">
      <EngineDetails :id="selected"
    /></el-scrollbar>
  </el-dialog>
</template>

<script>
import ChartLegend from '@/components/explore/ChartLegend';
import envScores from '@/utils/data/envScores';
import getChartWidth from '@/utils/helpers/getChartWidth';
import EngineDetails from '@/components/explore/EngineDetails';

export default {
  name: 'RadarChart',
  components: {
    ChartLegend,
    EngineDetails,
  },
  data() {
    return {
      indicator: 'bev',
      chartWidth: null,
      choices: [],
      selected: null,
      showModal: false,
      options: {
        chart: {
          fontFamily: 'Fira Sans',
          type: 'radar',
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: '',
        },
        xaxis: {
          categories: [
            'Climate',
            'Particulate',
            'Fossils',
            'Materials/Minerals',
            'Single score',
          ],
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
        plotOptions: {
          radar: {
            size: 200,
            polygons: {
              strokeColors: [
                '#de3700',
                '#f58b00',
                '#ffd500',
                '#92e000',
                '#2aa10f',
              ],
            },
          },
        },
        colors: ['#4d4d4f'],
      },
      series: [
        {
          name: '',
          data: [0, 0, 0, 50, 30],
        },
      ],
    };
  },
  created() {
    this.chartWidth = getChartWidth(450, 500, 550);
    let choices = [];
    for (let i in envScores) {
      const envScore = envScores[i];
      choices.push({
        id: envScore.id,
        name: envScore.chart,
      });
    }
    this.choices = choices;
    this.selected = choices[0].id;

    this.options = {
      ...this.options,
      title: {
        text: this.$t(`select.${choices[0].id}`),
      },
    };
    this.getChartColor(5);
  },
  methods: {
    updateChart() {
      const choice = this.selected;
      this.indicator = choice;
      let data = [];
      const engine = envScores.find((option) => option.id === choice);
      this.options = {
        ...this.options,
        title: {
          text: this.$t(`select.${engine.id}`),
        },
      };

      this.getChartColor(engine.stars);

      for (let i in engine.indicators) {
        const indicator = engine.indicators[i];
        const score = 100 - indicator.score;
        data.push(score);
      }
      this.series = [
        {
          name: engine.chart,
          data: data,
        },
      ];
    },
    getChartColor(star) {
      let color;
      if (star === 5) {
        color = ['#2aa10f'];
      }

      if (star === 4) {
        color = ['#92e000'];
      }

      if (star === 3) {
        color = ['#ffd500'];
      }

      if (star === 2) {
        color = ['#f58b00'];
      }

      if (star === 1) {
        color = ['#de3700'];
      }

      this.options = {
        ...this.options,
        colors: color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.engines-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-select {
    width: 500px;
  }
}

.btn-col {
  display: flex;
  justify-content: flex-end;
}

.chart-row {
  display: flex;
  align-items: center;
}
@include bp-down(md) {
  .chart-col {
    transform: scale(0.8);
    overflow: visible;
  }
  .btn-col {
    justify-content: center;
  }
}
</style>
